<template>
  <div class="row">
    <div class="col-8">
      <h1>{{contents.title}}</h1>
      <div v-html="contents.mainContent"></div>
      <form @submit.prevent="onSubmit">
        <div class="form-input">
          <textarea v-model="inputString" placeholder="Másold ide a módosítani kívánt szövegrészletet...akár soronként többet is egyszerre."></textarea>
        </div>
        <button type="submit" class="btn btn-primary float-end">Generálás</button>
      </form>
      <p>{{md5Hash}}</p>
    </div>
    <div class="col-4">

    </div>
  </div>
</template>

<script>
export default {
  name:'Md5',
  props:['globalData'],
  methods: {
    onSubmit() {
      this.globalData.loader.status = true

      let formData = new FormData();
      formData.append('test', this.$myApiKey);
      formData.append('inputString', this.inputString);

      this.$axios.post( '/api/v1/md5', formData)
          .then((response) => {
            console.log(response)
            let responseData = response.data
            if (responseData.status == false) {
              this.globalData.errors.push({msg: responseData.msg, type: 'error'})
              return false
            }
            this.md5Hash = responseData.data.md5
          })
          .catch(error => {
            console.log(error.message)
            console.error('There was an error!', error)
          })
          .then(() => {
            this.globalData.loader.status = false
          })
    }
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language].md5
    return{
      inputString: "",
      md5Hash: "",
      contents
    }
  }
}
</script>

<style>
textarea{
  margin-bottom: 2rem;
}
</style>